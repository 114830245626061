<template>
	<div>
		<b-form-select v-model="selected"
		               :options="options"
		               :class="validateError != '' ? 'box-border-color' : ''"
		               @input="handleInput"/>
		<span class="invalid-feedback-custom" v-if="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				selected: null,
				options: [
					{
						value: null,
						text: this.$t('select'),
					},
					{
						value: 1,
						text: this.$t('with_thesis'),
					},
					{
						value: 0,
						text: this.$t('without_thesis'),
					}
				]
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = newValue
			},
		},
		created() {
			this.selected = this.value
		},
		methods: {
			handleInput(event) {
				this.$emit('input', event)
			}
		}
	}
</script>
